import { 푸터_Mobile } from "./푸터_Mobile";
import { 마켓봄_장점_Mobile } from "./마켓봄_장점_Mobile";
import { 마켓봄_업데이트_소식_Mobile } from "./마켓봄_업데이트_소식_Mobile";
import { 랜딩_섹션6_Mobile } from "./랜딩_섹션6_Mobile";
import { CSR } from "./CSR";
import { 랜딩_섹션7_Mobile } from "./랜딩_섹션7_Mobile";
import { 랜딩_섹션1_Mobile } from "./랜딩_섹션1_Mobile";
import { 랜딩_섹션2_Mobile } from "./랜딩_섹션2_Mobile";
import { 랜딩_섹션4_Mobile } from "./랜딩_섹션4_Mobile";
import { 랜딩_섹션9_Mobile } from "./랜딩_섹션9_Mobile";
import { 랜딩_섹션5_Mobile } from "./랜딩_섹션5_Mobile";
import { CTA_회원가입2 } from "./CTA_회원가입_Mobile2";
import { 헤더_MobileAB } from "./헤더_MobileAB";
import Image from "next/image";
import Link from "next/link";
import { 링크 } from "./데이터/링크";
import EventBannerImageMobile from "/public/assets/mobile/images/pic/banner.png";

const 랜딩_Mobile = () => {
  return (
    <>
      <div>
        <Link
          href={링크.추천_이벤트_링크}
          target="_blank"
        >
          <Image
            style={{ width: "100%" }}
            src={EventBannerImageMobile}
            alt="마켓봄 추천하면 100% 지급"
          /> 
        </Link>
      </div>
      <div id="wrap">
        <헤더_MobileAB />
        <div id="container" className="main">
          <랜딩_섹션1_Mobile />
          <랜딩_섹션2_Mobile />
          <마켓봄_장점_Mobile />
          <랜딩_섹션4_Mobile />
          <랜딩_섹션5_Mobile />
          <CSR>
            <랜딩_섹션6_Mobile />
          </CSR>
          <랜딩_섹션7_Mobile />
          <마켓봄_업데이트_소식_Mobile />
          <CSR>
            <랜딩_섹션9_Mobile />
          </CSR>
        </div>
        <푸터_Mobile />
        <CTA_회원가입2 />
      </div>
    </>
  );
};
export default 랜딩_Mobile;
